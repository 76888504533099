<template>
  <v-row class="ma-0" style="height: calc(100vh - 64px); flex-wrap: nowrap">
    <v-col
      class="pa-0"
      cols="3"
      style="height: inherit; overflow-y: auto; position: relative"
      ref="ordersScroller"
    >
      <v-row
        no-gutters
        style="position: absolute; right: 20px; top: 18px; gap: 8px"
      >
        <v-btn
          v-if="!selectedOrders.length"
          x-small
          color="primary"
          @click="initShiftModal = true"
        >
          <div class="text-none body-2">Новая смена</div>
        </v-btn>
        <v-btn x-small icon @click="couriersPriorityModal = true">
          <v-icon>mdi-sort</v-icon>
        </v-btn>
      </v-row>
      <DeliveryShifts
        :shifts="shifts"
        :orders="filteredOrders"
        :selected-shift="selectedShift"
        :selected-orders="selectedOrders"
        :total-orders="orders"
        :filter-orders-by-shift="filterOrdersByShift"
        :shift-to-take-next-order="shiftToTakeNextOrder"
        @selectCourier="selectCourier($event)"
        @setOrdersToShift="setOrdersToShift()"
        @filterOrdersByShift="setFiltersOrdersByShift($event)"
        @selectOrder="selectOrder($event)"
        @clear="onClear()"
        class="py-3"
      />
    </v-col>
    <v-divider vertical />
    <v-col class="pa-0" cols="9">
      <v-row class="ma-0 pa-3" justify="space-between">
        <v-row class="ma-0" align="center">
          <v-select
            hide-details
            outlined
            dense
            :items="Object.values(outletById)"
            item-value="id"
            label="ТТ"
            :item-text="(val) => val.number + '. ' + val.address"
            v-model="filters.outlet"
            style="max-width: 200px"
            clearable
            class="mr-4"
          ></v-select>
          <v-checkbox
            label="Только без курьера"
            hide-details
            class="ma-0 pa-0"
            v-model="onlyWithoutCourier"
          />
        </v-row>
        <v-btn color="primary" @click="reviewsModal = true" class="mr-4">
          <div class="text-none">Отзывы</div>
        </v-btn>
        <v-btn color="primary" @click="mapMode = !mapMode" class="mr-4">
          <div class="text-none">
            {{ !mapMode ? "Посмотреть на карте" : "Открыть список заказов" }}
          </div>
        </v-btn>
        <v-btn color="primary" @click="loadAll()">
          <div class="text-none">Обновить</div>
        </v-btn>
      </v-row>
      <v-divider />
      <div style="overflow-y: auto; height: calc(100vh - 130px)">
        <DeliveryOrders
          v-if="!mapMode"
          :orders="filteredOrders"
          @selectOrder="selectOrder($event)"
          :selected-orders="selectedOrders"
        />
        <DeliveryMap
          v-else
          :orders="filteredOrders"
          :shifts="shifts"
          :shifts-key="shiftsRenderKey"
          ref="mapRef"
        />
      </div>
    </v-col>
    <InitShiftModal
      :model-value="initShiftModal"
      @update:modelValue="initShiftModal = $event"
      @shiftInited="updateShift($event, 'shift.start')"
    />
    <v-dialog v-model="reviewsModal" width="400px">
      <v-card v-if="reviewsModal">
        <div class="headline py-2 mx-2">Отзывы</div>
        <div style="max-height: 470px; overflow-y: auto">
          <v-col
            v-for="(orderReview, index) in orderReviews"
            :key="orderReview.id"
            class="px-3 py-0"
          >
            <v-divider v-if="index" class="m-1" />
            <v-col class="px-0 py-2">
              <v-row class="ma-0">
                <div class="body-2 font-weight-bold">
                  {{ orderReview.order.daily_number }}
                </div>
                <v-spacer />
                <div class="caption">
                  {{ parseDate(orderReview.created_at) }}
                </div>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0" cols="9">
                  <v-row class="pa-0 ma-0 body-2">
                    ТТ: {{ orderReview.order.outlet.number }}.
                    {{ orderReview.order.outlet.address }}
                  </v-row>
                  <v-row class="pa-0 ma-0 body-2" align="center">
                    Оценка: {{ orderReview.rating }}
                    <v-icon size="16px" style="margin-bottom: 1px" class="ml-1"
                      >mdi-star
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
          <div v-if="!orderReviews.length" class="mx-2 mb-2">
            Отзывов пока нет
          </div>
        </div>
      </v-card>
    </v-dialog>
    <ShiftsPriorityModal
      v-model="couriersPriorityModal"
      :shifts="shifts"
      @updatePriority="updatePriority"
    />
  </v-row>
</template>

<script setup>
import DeliveryOrders from "@/admin/views/deliveryAdmin/DeliveryOrders.vue";
import moment from "moment/moment";
import DeliveryShifts from "@/admin/views/deliveryAdmin/DeliveryShifts.vue";
import DeliveryMap from "@/admin/views/deliveryAdmin/DeliveryMap.vue";
import InitShiftModal from "@/admin/views/deliveryAdmin/InitShiftModal.vue";
import { clone, cloneDeep } from "lodash";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import Vue from "vue";
import store from "@/admin/store/store";
import ShiftsPriorityModal from "@/admin/views/deliveryAdmin/ShiftsPriorityModal.vue";

const loading = ref(false);
const filters = ref({});
const orders = ref([]);
const shifts = ref([]);
const reloadOrdersListInterval = ref(null);
const outletById = ref({});
const selectedOrders = ref([]);
const selectedShift = ref(null);
const filterOrdersByShift = ref(null);
const outletCourierWs = ref(null);
const tryReconnectOutletCourierWs = ref(false);
const outletCourierWsReconnectInterval = ref(null);
const onlyWithoutCourier = ref(true);
const lastValueOfOnlyWithoutCourier = ref(true);
const initShiftModal = ref(false);
const couriersPriorityModal = ref(false);
const takesAnOrderManual = ref(false);
const mapMode = ref(false);
const shiftsRenderKey = ref(0);
const reviewsModal = ref(false);
const orderReviews = ref([]);
const ordersScrollerRef = ref(null);
const mapRef = ref(null);
const app = Vue.prototype;

const sortShifts = () => {
  shifts.value = shifts.value.sort((a, b) =>
    a.prioroty - b.prioroty ? -1 : 1
  );
};

const updatePriority = (newShifts) => {
  const data = [];
  for (const [index, item] of newShifts.reverse().entries()) {
    data.push({
      shift: item.id,
      priority: index,
    });
    const shiftIndex = shifts.value.findIndex((v) => v.id === item.id);
    if (shiftIndex > -1) shifts.value[shiftIndex].priority = index;
  }
  sortShifts();
  app.$shift
    .sendPutSingle("set_priority", undefined, data)
    .then(() => {
      store.commit("setSnackbar", { color: "success", text: "Обновлено" });
      couriersPriorityModal.value = false;
    })
    .catch(() => {
      store.commit("setSnackbar", { color: "error", text: "Обновлено" });
    });
};

const courierOrdersCount = (user) => {
  return orders.value.filter((v) => v.courier?.id === user.id).length;
};

const shiftToTakeNextOrder = computed(() => {
  return shifts.value
    .sort((a, b) => (a.priority < b.priority ? 1 : -1))
    .filter((v) => !courierOrdersCount(v.user))[0];
});

const sortOrders = (a, b) => {
  const firstPackaged = a.user_status === app.$order.userStatusEnum.PACKAGED;
  const secondPackaged = b.user_status === app.$order.userStatusEnum.PACKAGED;
  if (firstPackaged && secondPackaged)
    return (a.packaged_at || 0) > (b.packaged_at || 0) ? 1 : -1;
  else if (firstPackaged) return -1;
  else if (secondPackaged) return 1;
  return a.preferred_time > b.preferred_time ? 1 : -1;
};
const onClear = () => {
  takesAnOrderManual.value = false;
  selectedShift.value = null;
  selectedOrders.value = [];
};
const loadOrders = async () => {
  app.$order
    .sendGetSingle("for_couriers", undefined, filters.value)
    .then((v) => {
      v.result.forEach((order) => {
        setPackagedWaitingTime(order);
      });
      orders.value = v.result;
      for (const el of orders.value) {
        const index = selectedOrders.value.findIndex((v) => v.id === el.id);
        if (index > -1) selectedOrders.value[index] = el;
      }
    });
};
const loadAll = async () => {
  await loadOrders();
  await loadShifts();
};
const loadShifts = () => {
  app.$shift.sendGetSingle("current", undefined, filters.value).then((v) => {
    let values = v.result;
    if (selectedShift.value) {
      const found = values.find((v) => v.id === selectedShift.value.id);
      if (found) selectedShift.value = found;
    }
    shifts.value = values;
  });
};
const setPackagedWaitingTime = (order) => {
  const now = moment.now();
  if (order.packaged_at) {
    const minutes = Math.floor(
      (now - moment.utc(order.packaged_at).local()) / 1000 / 60
    );
    order.packagedWaitingTime = minutes;
  } else order.packagedWaitingTime = 0;
};
const deliveryOrdersInitScroll = () => {
  const scroller = ordersScrollerRef.value;
  const children = scroller.firstChild.children;
  const index = orders.value.findIndex((v) => v.id === app.$order.item?.id);
  if (index < -1) return;
  let height = 0;
  for (let i = 0; i < index; i++) {
    height += children.item(i).clientHeight + 8;
  }
  scroller.scrollTo(0, height);
};
const selectOrder = (order) => {
  const index = selectedOrders.value.findIndex((v) => v.id === order.id);
  if (index > -1) {
    selectedOrders.value.splice(index, 1);
    if (!selectedOrders.value.length && !takesAnOrderManual.value) {
      selectedShift.value = null;
    }
  } else {
    selectedShift.value = shifts.value.find((v) => v.takesAnOrder) || null;
    selectedOrders.value.push(order);
    if (shiftToTakeNextOrder.value) {
      selectedShift.value = shiftToTakeNextOrder.value;
    }
  }
};
const selectCourier = (shift) => {
  if (selectedShift.value && selectedShift.value.id === shift.id) {
    selectedShift.value = null;
    takesAnOrderManual.value = false;
  } else {
    selectedShift.value = shift;
    takesAnOrderManual.value = true;
  }
};
const setOrdersToShift = () => {
  if (!selectedShift.value || !selectedOrders.value.length) return;
  const items = [];
  for (const order of selectedOrders.value) {
    items.push({ order: order.id, courier: selectedShift.value.user.id });
  }
  app.$order
    .sendPostSingle("set_couriers", undefined, { items })
    .then(() => {
      selectedOrders.value.forEach(
        (v) => (v.courier = selectedShift.value.user)
      );
      selectedShift.value = null;
      selectedOrders.value = [];
      store.commit("setSnackbar", {
        color: "success",
        text: "Успешно",
      });
    })
    .catch(() => {
      store.commit("setSnackbar", {
        color: "fail",
        text: "Ошибка",
      });
    });
};
const setFiltersOrdersByShift = (shift) => {
  if (!filterOrdersByShift.value) {
    lastValueOfOnlyWithoutCourier.value = clone(onlyWithoutCourier.value);
    onlyWithoutCourier.value = false;
    filterOrdersByShift.value = shift;
  } else if (filterOrdersByShift.value.id === shift.id) {
    onlyWithoutCourier.value = clone(lastValueOfOnlyWithoutCourier.value);
    filterOrdersByShift.value = null;
  } else {
    lastValueOfOnlyWithoutCourier.value = clone(onlyWithoutCourier.value);
    onlyWithoutCourier.value = false;
    filterOrdersByShift.value = shift;
  }
};
const setOutletCourierWs = () => {
  if (outletCourierWs.value) {
    tryReconnectOutletCourierWs.value = false;
    outletCourierWs.value.close();
  }
  const token = localStorage.getItem("access");
  const protocol = window.location.protocol === "https:" ? "wss" : "ws";
  const host = window.location.host.includes("127.0.0.1")
    ? "127.0.0.1:8100"
    : window.location.host;
  outletCourierWs.value = new WebSocket(
    `${protocol}://${host}/ws/outlet_courier/all/?token=${token}`
  );
  outletCourierWs.value.onmessage = outletCourierWsHandler;
  outletCourierWs.value.onclose = () => {
    if (
      !tryReconnectOutletCourierWs.value &&
      outletCourierWsReconnectInterval.value
    )
      clearInterval(outletCourierWsReconnectInterval.value);
    if (tryReconnectOutletCourierWs.value)
      outletCourierWsReconnectInterval.value = setInterval(() => {
        setOutletCourierWs();
      }, 5000);
  };
};
const outletCourierWsHandler = (res) => {
  const data = JSON.parse(res.data);
  switch (data.type) {
    case "orders.updated":
      return updateOrders(data.data);
    case "order.updated":
      return updateOrders([data.data]);
    case "shift.start":
      return updateShift(data.data, data.type);
    case "shift.end":
      return updateShift(data.data, data.type);
    case "shift.start_break":
      return updateShift(data.data, data.type);
    case "shift.start_waiting":
      return updateShift(data.data, data.type);
    case "shift.start_working":
      return updateShift(data.data, data.type);
    case "shift.start_going_back":
      return updateShift(data.data, data.type);
    case "courier_location.updated":
      return updateShiftByCoords(data.data);
    case "order_review.created":
      return onOrderReviewCreated(data.data);
    case "shifts.updated":
      return onShiftsUpdated(data.data);
  }
};

const onShiftsUpdated = (items) => {
  for (const item of items) {
    const index = shifts.value.findIndex((v) => v.id === item.id);
    if (index > -1) shifts.values = item;
  }
  sortShifts()
};

const onOrderReviewCreated = (review) => {
  if (reviewsModal.value) {
    const found = orderReviews.value.find((v) => v.id === review.id);
    if (!found) orderReviews.value.unshift(review);
  }
};
const updateShiftByCoords = (track) => {
  const shift = shifts.value.find((v) => v.user.id === track.created_by);
  if (!shift) return;
  shift.last_position = track.coords;
  mapRef.value?.loadSelectedShiftTrack();
  shifts.valueRenderKey++;
};
const updateOrders = (orders) => {
  for (const order of orders) {
    if (order.pickup_type !== app.$order.pickupTypeEnum.DELIVERY) continue;
    if (filters.value.outlet && order.outlet.id !== filters.value.outlet) {
      continue;
    }
    const index = orders.findIndex((v) => v.id === order.id);
    if (index < 0) {
      if (
        (!filters.value.outlet || filters.value.outlet === order.outlet.id) &&
        order.user_status !== app.$order.userStatusEnum.DECLINED
      ) {
        orders.value.push(order);
        return;
      }
    }
    Object.assign(orders.value[index], order);
  }
};
const updateShift = (shift, action) => {
  if (shift.outlet.id !== filters.value.outlet && filters.value.outlet) return;
  const index = shifts.value.findIndex((v) => v.id === shift.id);
  if (index < 0 && action === "shift.start") {
    shifts.value.push(shift);
  } else if (index > -1 && action === "shift.end") {
    shifts.value.splice(index, 1);
  } else {
    if (index < 0) return;
    else {
      Object.assign(shifts.value[index], shift);
    }
  }
  if (selectedShift.value?.id === shift.id) selectedShift.value = shift;
};
const parseDate = (date) => {
  return window.moment.localDateTime(date);
};

const filteredOrders = computed(() => {
  let res = orders.value;
  if (filterOrdersByShift.value)
    res = res.filter(
      (v) => v.courier?.id === filterOrdersByShift.value.user.id
    );
  if (onlyWithoutCourier.value) res = res.filter((v) => !v.courier);
  return res.sort((a, b) => sortOrders(a, b));
});

onMounted(() => {
  outletById.value = {};
  for (const el of app.$auth.user.outlets) {
    outletById.value[el.id] = el;
  }
  loadAll();
  reloadOrdersListInterval.value = setInterval(() => loadOrders(), 30 * 1000);
  setOutletCourierWs();
});

onBeforeUnmount(() => {
  if (reloadOrdersListInterval.value)
    clearInterval(reloadOrdersListInterval.value);
  if (outletCourierWs.value) {
    tryReconnectOutletCourierWs.value = false;
    outletCourierWs.value.close();
  }
});

watch(reviewsModal, (v) => {
  if (v) {
    app.$orderReview
      .loadList({
        order_pickup_type: app.$order.pickupTypeEnum.DELIVERY,
        start_date: moment()
          .subtract(18, "hours")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        page_size: "all",
      })
      .then((v) => {
        orderReviews.value = v;
      });
  }
});
</script>

<style scoped></style>
