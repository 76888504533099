<template>
  <template-item
    :model="$nomenclature"
    :show-actions="
      $auth.hasRight('nomenclature__edit_information') &&
      $route.name === 'nomenclatureItem'
    "
    title-prepend="Номенклатура / "
    object-key-for-title="name"
    :top-divider-title="
      $route.name === 'nomenclatureItem'
        ? 'Основная информация'
        : 'Торговые точки'
    "
  >
    <template
      v-slot:topDividerActions
      v-if="$auth.hasRight('nomenclature__edit_information')"
    >
      <v-btn
          :loading="$nomenclature.loadings.action"
          height="40"
          depressed
          class="rounded-lg ml-3"
          color="primary"
          @click="openSetToOtherVisibleOutletNomenclatureModal()"
      >
        <div class="text-none body-2 px-2">Применить к остальным</div>
      </v-btn>
      <v-btn
        :loading="$nomenclature.loadings.action"
        height="40"
        depressed
        class="rounded-lg ml-3"
        color="primary"
        @click="saveVisibleOutletNomenclature()"
      >
        <div class="text-none body-2 px-2">Сохранить</div>
      </v-btn>
    </template>
    <template v-slot="{ object, rules }">
      <v-row no-gutters align="center" class="mb-5 mx-0" v-if="object.id">
        <v-btn
          @click="$router.replace({ name: 'nomenclatureItem' })"
          :color="$route.name === 'nomenclatureItem' ? 'primary' : ''"
          depressed
          class="mr-3 rounded-lg text-none body-2"
          >Общая информация
        </v-btn>
        <v-btn
          @click="$router.replace({ name: 'nomenclatureItemOutlets' })"
          :color="$route.name === 'nomenclatureItemOutlets' ? 'primary' : ''"
          depressed
          class="mr-3 rounded-lg text-none body-2"
          >Торговые точки
        </v-btn>
      </v-row>
      <router-view></router-view>
    </template>
  </template-item>
</template>

<script>
import TemplateItem from "@/components/template/TemplateItem.vue";

export default {
  name: "NomenclatureItem",
  components: { TemplateItem },
  data: () => ({
    loading: false,
  }),
  methods: {
    saveVisibleOutletNomenclature() {
      this.$eventBus.$emit("saveVisibleOutletNomenclature");
    },
    openSetToOtherVisibleOutletNomenclatureModal() {
      this.$eventBus.$emit("openSetToOtherVisibleOutletNomenclatureModal");
    }
  },
  mounted() {
    this.$nomenclature.loadItem(this.$route.params.nomenclatureId);
  },
};
</script>

<style scoped>
.v-input--checkbox :deep(.v-messages) {
  display: none !important;
}
</style>
