const m = require('air-vue-model')()
m.url = 'banners'
m.name = 'banner'
m.setPagination({
    page_size: 'all'
})
m.text = {
    itemTitle: 'Баннер',
    listTitle: 'Баннеры'
}
m.default = {
    id: null,
    title: null,
    subtitle: null,
    text_color: '#FFFFFF',
    link: null,
    mobile_app_page: null,
    sorting: 0,
    start_date: null,
    end_date: null,
    active: true,
    can_be_hidden: true,
}
m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/banners/BannerList'),
    },
    {
        name: 'item',
        component: require('@/admin/views/banners/BannerItem'),
        single: true
    }
]

m.mobileAppPages = [
    {
        label: 'Промо (Личиска)',
        value: 1
    }
]

module.exports = m