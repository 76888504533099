<template>
  <v-card width="100%" flat class="ma-0 py-1">
    <v-row no-gutters class="" align="center">
      <v-btn icon small class="mr-2" @click="show = !show">
        <v-icon size="20">{{
          show ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>

      <v-col class="pa-0" @click="$emit('groupClicked')">
          <div class="text-subtitle-1 mr-2 link font-weight-bold">
            <span>{{ item.name }}</span>
          </div>
      </v-col>
    </v-row>
    <v-col class="pa-0 ml-6 mt-2" v-if="show">
      <v-col
        class="pa-0"
        v-for="(productBlank, index) in item.items"
        :key="JSON.stringify(productBlank)"
        @click="$emit('itemClicked', productBlank)"
      >
        <v-row no-gutters align="center" :class="{'mt-2': index}">
          <v-col>
            <v-row no-gutters align="center">
                <div class="mx-3 link body-2 font-weight-medium">
                  <span>{{ productBlank.name }}</span>
                </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </v-card>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  item: Object,
});

defineEmits(['itemClicked', 'groupClicked'])

const show = ref(false);
</script>

<style scoped></style>
