<template>
  <v-col class="pa-0">
    <v-row align="center" class="my-3" no-gutters>
      <div class="text-h6 mr-3">Общая информация</div>
      <v-divider />
      <v-btn
        :loading="$user.loadings.save"
        class="ml-3 rounded-lg text-none body-2"
        color="primary"
        depressed
        @click="save"
        >Сохранить
      </v-btn>
    </v-row>
    <v-text-field
      v-model="user.phone"
      dense
      hide-details
      label="Номер телефона"
      outlined
    ></v-text-field>
    <v-text-field
      v-model="user.first_name"
      class="mt-3"
      dense
      hide-details
      label="Имя"
      outlined
    ></v-text-field>
    <lazy-select
      v-model="user.outlets"
      :item-text="(val) => val.number + '. ' + val.address"
      :lazy="false"
      :model="$outlet"
      class="mt-3"
      clearable
      label="Торговые точки"
      multiple
      preload
      set-to="list"
    ></lazy-select>
    <v-select
      v-model="user.user_type"
      :items="$auth.user.is_superuser ? $user.userTypes : $auth.getAvailableTypes()"
      class="mt-3"
      dense
      hide-details
      item-text="title"
      item-value="value"
      label="Тип пользователя"
      outlined
    ></v-select>
    <lazy-select
      v-model="user.user_roles"
      :items="$auth.user.is_superuser ? $userRole.list : $auth.getAvailableRoles()"
      :lazy="false"
      class="mt-3"
      clearable
      item-text="name"
      label="Роли"
      multiple
    ></lazy-select>
    <template v-if="$auth.hasRight('users__edit_administrative_settings')">
    <v-checkbox
      v-model="user.is_staff"
      class="pa-0 m-0"
      hide-details
      label="Доступ к панели администратора"
    ></v-checkbox>
    <v-checkbox
      v-model="user.is_superuser"
      class="pa-0 m-0"
      hide-details
      label="Суперпользователь"
    ></v-checkbox>
    <v-checkbox
      v-model="user.developer"
      class="pa-0 m-0"
      hide-details
      label="Тестирование нового функционала"
    ></v-checkbox>
    </template>
    <v-text-field
      v-model="user.password"
      class="mt-5"
      dense
      hide-details
      label="Пароль"
      outlined
    ></v-text-field>
    <v-col v-if="user.user_type !== 1" class="pa-0">
      <div class="font-weight-bold row items-center mx-0 mt-5 mb-2">
        <div>Google Authenticator</div>
        <div
          v-if="user.has_authenticator && $auth.user.is_superuser"
          class="ml-2"
        >
          <v-btn color="grey" icon small @click.stop="removeAuthenticator(el)">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <div :class="user.has_authenticator ? 'green--text' : 'red--text'">
        {{ user.has_authenticator ? "Подключен" : "Не подключен" }}
      </div>
      <div
        v-if="user.has_authenticator"
        :class="user.has_authenticator ? 'green--text' : 'red--text'"
        class="mt-2"
      >
        {{ user.authenticator_enabled ? "Верифицирован" : "Не верифицирован" }}
      </div>
    </v-col>
    <v-col v-if="user.user_type === 1 && user.order_details" class="pa-0">
      <div class="font-weight-bold row items-center mx-0 mt-5 mb-2">
        Информация о госте
      </div>
      <div>Дата регистрации: {{ formatDate(user.date_joined) }}</div>
      <div class="mt-1">Последний вход: {{ formatDate(user.last_login) }}</div>
      <div :style="{
        fontWeight: isUserBlocked ? '500' : ''
      }">Пользователь заблокирован:
      {{!user.is_active ? 'Да' : user.disabled_until ? `до ${formatDate(user.disabled_until)}` : 'Нет'}}
      </div>
      <v-divider class="my-3" style="max-width: 340px" />
      <div class="mt-1">
        Пользователь заполнил информацию:
        {{ user.user_information_is_complete ? 'Да' : "Нет" }}
      </div>
      <div class="mt-1">
        День рождения:
        {{ user.birthday ? formatDate(user.birthday, true) : "Не указано" }}
      </div>
      <div class="mt-1">Пол: {{ getUserSex(user.sex) }}</div>
      <v-divider class="my-3" style="max-width: 340px" />
      <div class="mt-1">
        Количество заказов: {{ user.order_details.amount }}
      </div>
      <div class="mt-1">Сумма заказов: {{ user.order_details.total_sum }}</div>
      <div class="mt-1">Средний чек: {{ user.order_details.avg_sum }}</div>
    </v-col>
  </v-col>
</template>

<script>
export default {
  name: "User",
  mounted() {
    this.$userRole.loadList();
  },
  computed: {
    user() {
      return this.$user.item;
    },
    isUserBlocked() {
      return !this.user.is_active || !!this.user.disabled_until
    }
  },
  methods: {
    formatDate(value, withoutTime = false) {
      if (!value) return "Отсутствует";
      return moment.utc(value).local().format(`DD.MM.YYYY ${withoutTime ? '' : 'HH:mm:ss'}`);
    },
    getUserSex(value) {
      if (!value) return "Не указано";
      return value === "male" ? "Мужской" : "Женский";
    },
    removeAuthenticator() {
      this.user.has_authenticator = false;
      this.user.authenticator_enabled = false;
      this.$user
        .sendPostSingle("disable_authenticator", this.user.id)
        .then(() => {
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Успешно",
          });
        });
    },
    save() {
      const { promise, created } = this.$user.updateOrCreate();
      promise
        .then((res) => {
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Сохранено",
          });
          if (created) {
            this.$router.replace({
              name: "userItem",
              params: { userId: res.id },
            });
          }
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "red",
            text: "Ошибка сохранения",
          });
        });
    },
  },
};
</script>

<style scoped></style>
