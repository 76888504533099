<template>
  <v-btn
    height="40"
    color="primary"
    depressed
    class="rounded-lg"
    v-if="showCreateButton"
    :to="{
      name: nameTo || `${model.name}Item`,
      params: { [paramsName]: 'new' },
    }"
  >
    <div class="text-none body-2 px-2">{{createButtonText || 'Создать'}}</div>
  </v-btn>
</template>

<script>
export default {
  name: "TemplateListActions",
  props: {
    model: Object,
    showCreateButton: Boolean,
    nameTo: String,
    createButtonText: String,
  },
  data: () => ({
    menu: false,
    loading: false,
  }),
  computed: {
    paramsName() {
      return `${this.model.name}${
        this.model.pk.charAt(0).toUpperCase() + this.model.pk.slice(1)
      }`;
    },
  },
};
</script>
