<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Торговые точки</div>
            <v-divider/>
            <v-btn
                v-if="$auth.hasRight('products__edit_product_outlets')"
                :loading="$product.loadings.action" @click="saveOutlets" depressed color="primary"
                   class="ml-3 rounded-lg text-none body-2">
                Сохранить
            </v-btn>
        </v-row>
        <v-row no-gutters align="center" v-if="results.length">
            <v-btn @click="setValues('pickup')" depressed small>
                <v-icon size="17">
                    {{ !results[0].pickup ? 'mdi-checkbox-blank-circle-outline' : 'mdi-check-circle-outline' }}
                </v-icon>
                <div class="pl-2">Самовывоз/В зале</div>
            </v-btn>
            <v-btn @click="setValues('delivery')" depressed small class="ml-4">
                <v-icon size="17">
                    {{ !results[0].delivery ? 'mdi-checkbox-blank-circle-outline' : 'mdi-check-circle-outline' }}
                </v-icon>
                <div class="pl-2">Доставка</div>
            </v-btn>
          <v-btn @click="setValues('yandex_eda')" depressed small class="ml-4">
            <v-icon size="17">
              {{
                !results[0].yandex_eda
                    ? "mdi-checkbox-blank-circle-outline"
                    : "mdi-check-circle-outline"
              }}
            </v-icon>
            <div class="pl-2">Яндекс еда</div>
          </v-btn>
        </v-row>
        <v-data-table
            no-data-text="Нет данных"
            hide-default-footer
            :headers="headers"
            :items="results"
            :items-per-page="999999"
        >
            <template v-slot:item.pickup="{ item }">
                <v-checkbox class="mt-0 pa-0" hide-details
                            v-model="item.pickup"
                ></v-checkbox>
            </template>
            <template v-slot:item.delivery="{ item }">
                <v-checkbox class="ma-0 pa-0" hide-details
                            v-model="item.delivery"
                ></v-checkbox>
            </template>
          <template v-slot:item.yandex_eda="{ item }">
            <v-checkbox class="ma-0 pa-0" hide-details
                        v-model="item.yandex_eda"
            ></v-checkbox>
          </template>
        </v-data-table>
    </v-col>
</template>

<script>

export default {
    name: 'ProductItemIngredients',
    props: {
        item: Object
    },
    components: {},
    data: () => ({
        headers: [
            {text: 'Адрес', value: 'address'},
            {text: 'Самовывоз/В зале', value: 'pickup'},
            {text: 'Доставка', value: 'delivery'},
          {text: 'Яндекс еда', value: 'yandex_eda'},
        ],
        results: []
    }),
    watch: {
        '$outlet.list'() {
            this.rebuildTable()
        }
    },
    methods: {
        rebuildTable() {
            const results = []
            this.$outlet.list.forEach(outlet => {
                const currentProductOutlet = this.$productOutlet.list.find(v => outlet.id === v.outlet)
                results.push({
                    outlet: outlet.id,
                    address: outlet.number + '. ' + outlet.address,
                    delivery: currentProductOutlet?.delivery || false,
                    pickup: currentProductOutlet?.pickup || false,
                  yandex_eda: currentProductOutlet?.yandex_eda || false,
                })
            })
            this.results = results
        },
        saveOutlets() {
            this.$product.sendPutSingle('set_available_outlets', this.$product.item.id, this.results).then(res => {
                this.$store.commit('setSnackbar', {color: 'success', text: 'Торговые точки сохранены'})
            })
        },
        setValues(key) {
            const setValue = !this.results[0][key]
            this.results.forEach(v => v[key] = setValue)
        },
    },
    created() {
        this.$productOutlet.list = []
        this.$productOutlet.loadList({page_size: 'all', product: this.$product.item.id}).then(res => {
            this.rebuildTable()
        })
    }
}
</script>

<style scoped>

</style>