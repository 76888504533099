<template>
  <template-list
    :model="$banner"
    :show-create-button="$auth.hasRight('banner__access')"
    :filter-list="(item) => (showHidden ? true : item.active)"
  >
    <template v-slot:actions>
      <v-checkbox
        label="Показать скрытые"
        v-model="showHidden"
        hide-details
        class="mr-4 mt-0"
      />
    </template>
    <template v-slot="{ object, to, index }">
      <v-col class="pa-0">
        <router-link :to="to">
          <v-row no-gutters align="center">
            <v-img max-width="152" height="36" aspect-ratio="1" contain
                   :src="object.background_image || '/images/not-found.png'" style="margin-right: 10px"/>
            <div class="text-subtitle-1 link font-weight-bold ml-3">
              {{ object.title || 'Баннер без названия' }}
            </div>
            <v-spacer />
            <div
              class="mr-1 text-caption"
              :class="{
                'text--error': !isActive(object),
                'text--success': isActive(object),
              }"
            >
              {{ isActive(object) ? "Активен" : "Не активен" }}
            </div>
          </v-row>
        </router-link>
      </v-col>
    </template>
  </template-list>
</template>

<script>
import moment from "moment";

export default {
  name: "BannerList",
  data: () => {
    return {
      showHidden: false,
    };
  },
  methods: {
    isActive(obj) {
      if (!obj.active) return false;
      const now = moment().format("YYYY-MM-DD HH:mm:ss");
      if (obj.start_date && obj.start_date > now) return false;
      if (obj.end_date && obj.end_date < now) return false;
      return true
    },
  },
};
</script>

<style scoped>
.text--success {
  color: #56c224;
}

.text--error {
  color: #bf533f;
}
</style>
