const m = require('air-vue-model')()
m.url = 'nomenclature'
m.name = 'nomenclature'

m.text = {
    listTitle: 'Номенклатура',
    itemTitle: 'Номенклатура',
}

m.setPagination({
    page_size: 'all'
})


m.default = {
    vendor_code: '',
    name: '',
    client_name: '',
}

m.routes = [
]
module.exports = m