<template>
  <div>
    <template-item
      v-show="isItemPage"
      :model="$ingredientGroup"
      :show-delete="false"
      v-slot="{ object, rules }"
      :after-save="afterSave"
      :title="title"
      ref="templateItemRef"
    >
    </template-item>
    <v-row align="center" class="px-4 mb-6 my-0" v-if="!isItemPage">
      <div class="text-h6 mr-3">Основная информация</div>
      <v-divider />
      <v-btn
        v-if="$auth.hasRight('products__edit_information')"
        height="40"
        depressed
        class="rounded-lg ml-3"
        color="primary"
        @click="save()"
      >
        <div class="text-none body-2 px-2">Сохранить</div>
      </v-btn>
    </v-row>
    <v-col class="pa-0" :class="{ 'px-3': isItemPage }">
      <div>
        Наименование из учетной системы:
        <span class="font-weight-bold">{{
          $ingredientGroup.item.vendor_name || "Отсутствует"
        }}</span>
      </div>
      <v-text-field
        v-model="$ingredientGroup.item.name"
        outlined
        hide-details
        dense
        label="Клиентское наименование"
        class="mt-4"
      />
      <v-text-field
        v-model.number="$ingredientGroup.item.sorting"
        outlined
        hide-details
        dense
        label="Сортировка"
        class="mt-4"
      />
      <v-checkbox
          v-model="$ingredientGroup.item.include_modifiers_to_sticker_name"
          class="pa-0 m-0"
          hide-details
          label="Добавлять модификаторы в имя блюда на этикетке"
      ></v-checkbox>
    </v-col>
  </div>
</template>

<script>
import ImageUploader from "@/admin/components/ImageUploader.vue";
import DiscountSystemInformation from "@/admin/views/discountSystems/DiscountSystemInformation.vue";
import ProductIngredientGroupTabs from "@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupTabs.vue";
import { cloneDeep, isEqual } from "lodash";

export default {
  name: "IngredientGroupItem",
  components: {
    ProductIngredientGroupTabs,
    DiscountSystemInformation,
    ImageUploader,
  },
  data: () => {
    return {
      initialItem: null,
      eventsToClear: [],
    };
  },
  computed: {
    isItemPage() {
      return this.$route.name === "ingredientGroupItem";
    },
    title() {
      return "Модификаторы / " + this.$ingredientGroup.item.vendor_name;
    },
  },
  methods: {
    save() {
      this.$refs.templateItemRef.save();
    },
    afterSave() {
      this.initialItem = cloneDeep(this.$ingredientGroup.item);
    },
  },
  mounted() {
    this.initialItem = cloneDeep(this.$ingredientGroup.item);
    this.eventsToClear.push(
      this.$router.beforeEach((to, from, next) => {
        if (!isEqual(this.initialItem, this.$ingredientGroup.item)) {
          const answer = window.confirm(
            "Если вы уйдете со страницы, то потеряете все несохраненные данные"
          );
          if (answer) {
            if (this.eventsToClear.length) this.eventsToClear.map((v) => v());
            next();
          } else {
            this.$store.commit("setProductDrawer", true);
          }
        } else next();
      })
    );
    window.onbeforeunload = () => {
      if (!isEqual(this.initialItem, this.$ingredientGroup.item)) {
        return confirm();
      }
    };
  },
  beforeDestroy() {
    if (this.eventsToClear.length) this.eventsToClear.map((v) => v());
  },
};
</script>
