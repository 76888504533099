const m = require('air-vue-model')()
m.url = 'reasons'
m.name = 'reason'
m.setPagination({
    page_size: 'all'
})

m.text = {
    listTitle: 'Список причин', itemTitle: 'Причина',
}

m.routes = [
    {name: 'list', component: require('../admin/views/reason/Reasons')},
    {name: 'item', component: require('../admin/views/reason/Reason'), single: true},
]

m.filters = {
    search: null,
    type: 1,
}

m.default = {
    type: 1,
    text: '',
}

m.types = [
    {
        label: 'Блок. оборудования',
        value: 1
    },
    {
        label: 'Блок. номенклатуры',
        value: 2
    },
    {
        label: 'Блок. точки для онлайн заказов',
        value: 3
    },
    {
        label: 'Блок. точки',
        value: 4
    },
    {
        label: 'Блок. пользователя',
        value: 5
    },
    {
        label: 'Тех. перерыв',
        value: 6
    },
    {
        label: 'Закрытие обращения',
        value: 7
    },
    {
        label: 'Яндекс еда',
        value: 8
    },
]
module.exports = m