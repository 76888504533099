<template>
  <v-col class="pa-0">
    <v-text-field
      v-model="object.name"
      class="mb-3 mt-5"
      dense
      hide-details
      label="Наименование"
      outlined
      readonly
    ></v-text-field>
    <v-text-field
      v-model="object.vendor_code"
      class="mb-3"
      dense
      hide-details
      label="Код"
      outlined
      readonly
    ></v-text-field>
    <v-text-field
      v-model="object.client_name"
      class="mb-3"
      dense
      hide-details
      label="Пользовательское наименование"
      outlined
    ></v-text-field>
    <v-select
      v-model="object.unit"
      :items="$store.state.units"
      class="mb-3"
      dense
      hide-details
      item-text="title"
      item-value="name"
      label="Единица измерения"
      outlined
    ></v-select>
    <v-text-field
      v-model="object.min_amount_for_order"
      class="mb-3"
      dense
      hide-details
      label="Минимальное количество для заказа"
      outlined
      type="number"
      @change="$event === '' ? (object.min_amount_for_order = null) : undefined"
    ></v-text-field>
    <v-text-field
      v-model="object.min_amount_for_notification"
      class="mb-3"
      dense
      hide-details
      label="Минимальное количество для оповещения"
      outlined
      type="number"
      @change="
        $event === '' ? (object.min_amount_for_notification = null) : undefined
      "
    ></v-text-field>
    <v-text-field
      v-model="object.ingredients_text"
      class="mb-3"
      dense
      hide-details
      label="Состав (через запятую, без точки в конце)"
      outlined
      @change="$event === '' ? (object.ingredients_text = null) : void 0"
    ></v-text-field>
    <v-checkbox
      v-model="object.use_in_analytics_by_disabled"
      label="Учитывать при формировании аналитики по недоступной номенклатуре"
    />
    <v-checkbox
      v-model="object.notify_about_disabled"
      label="Уведомлять в телеграм когда недоступно"
    />
  </v-col>
</template>

<script>
export default {
  name: "NomenclatureItemMainInfo",
  data: () => ({
  }),
  methods: {
  },
  computed: {
    object() {
      return this.$nomenclature.item
    }
  },
};
</script>

<style scoped>
</style>
