<template>
  <div>
    <template-list
      :model="$productBlankGroup"
      :auto-load-list="false"
      :show-create-button="$auth.hasRight('products__edit_information')"
      create-button-text="Создать группу"
    >
      <template v-slot:actions>
        <v-btn
          height="40"
          color="primary"
          depressed
          class="rounded-lg mr-3"
          @click="openBlankCreation"
        >
          <div class="text-none body-2 px-2">Создать заготовку</div>
        </v-btn>
      </template>
      <template v-slot="{ object, to }">
        <ProductBlankGroupItemRow
          :item="object"
          @itemClicked="onItemClick"
          @groupClicked="$router.push(to)"
        />
      </template>
    </template-list>
    <v-dialog v-model="dialog" max-width="450">
      <v-card
        v-if="dialogItem"
        class="pa-5 rounded-lg"
        style="overflow-x: auto"
      >
        <div class="font-weight-bold mb-5">
          {{ dialogItem.id ? "Обновление заготовки" : "Создание заготовки" }}
        </div>
        <v-text-field
          v-model="dialogItem.name"
          label="Наименование"
          hide-details
          class="mb-3"
          outlined
        />
        <v-text-field
          v-model.number="dialogItem.shelf_life"
          dense
          hide-details
          label="Срок хранения (часы)"
          outlined
          type="number"
          class="mb-3"
        />
        <v-select
          v-model="dialogItem.group"
          :items="$productBlankGroup.list"
          item-text="name"
          item-value="id"
          label="Группа"
          hide-details
          class="mb-3"
          outlined
        />
        <v-row class="px-2 mt-0">
          <v-btn
            :disabled="!dialogItem.name || !dialogItem.group"
            :loading="dialogLoading"
            color="primary"
            @click="saveDialogItem()"
            >Сохранить
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="dialogItem.id"
            :loading="dialogLoading"
            color="error"
            outlined
            @click="deleteDialogItem()"
            >Удалить
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductBlankGroupItemRow from "@/admin/views/productBlankGroups/ProductBlankGroupItemRow.vue";
import { cloneDeep } from "lodash";

export default {
  name: "ProductBlankList",
  components: { ProductBlankGroupItemRow },
  data: () => {
    return {
      dialog: false,
      dialogItem: null,
      dialogLoading: false,
    };
  },
  methods: {
    async saveDialogItem() {
      try {
        this.dialogLoading = true;
        const res = await this.$productBlank.updateOrCreate(this.dialogItem)
          .promise;
        this.dialogItem = res;
        for (const group of this.$productBlankGroup.list) {
          const index = group.items.findIndex(
            (v) => v.id === this.dialogItem.id
          );
          if (index > -1 && group.id === this.dialogItem.group)
            group.items[index] = res;
          else if (index > -1 && group.id !== this.dialogItem.group) {
            group.items.splice(index, 1);
          } else if (index === -1 && group.id === this.dialogItem.group) {
            group.items.push(this.dialogItem);
          }
        }
        this.closeDialogItem();
      } catch {
      } finally {
        this.dialogLoading = false;
      }
    },
    deleteDialogItem() {
      if (!this.dialogItem) return;
      try {
        this.$productBlank.delete(this.dialogItem.id);
        for (const group of this.$productBlank.list) {
          const index = group.items.findIndex(
            (v) => v.id === this.dialogItem.id
          );
          if (index > -1) group.items.splice(index, 1);
        }
        this.closeDialogItem();
      } catch {
      } finally {
        this.dialogLoading = false;
      }
    },
    onItemClick(item) {
      this.dialog = true;
      this.dialogItem = cloneDeep(item);
    },
    openBlankCreation() {
      this.dialogItem = {};
      this.dialog = true;
    },
    closeDialogItem() {
      this.dialogItem = null;
      this.dialog = false;
      this.dialogLoading = false;
    },
  },
  mounted() {
    this.$productBlankGroup.pagination.page_size = "all";
  },
};
</script>

<style scoped>
.text--success {
  color: #56c224;
}

.text--error {
  color: #bf533f;
}
</style>
