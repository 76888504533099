<template>
  <v-col class="pa-0">
    <v-row no-gutters align="center" v-if="items.length">
      <v-btn depressed small @click="toggle()">
        <v-icon size="17">
          {{
            !this.$outletNomenclature.list[0].visible
              ? "mdi-checkbox-blank-circle-outline"
              : "mdi-check-circle-outline"
          }}
        </v-icon>
        <div class="pl-2">Видимость</div>
      </v-btn>
    </v-row>
    <v-data-table
      no-data-text="Нет данных"
      hide-default-footer
      :headers="headers"
      :items="items"
      :items-per-page="999999"
    >
      <template v-slot:item.outlet="{ item }">
        {{ outletById[item.outlet].number }}.
        {{ outletById[item.outlet].address }}
      </template>
      <template v-slot:item.visible="{ item }">
        <v-checkbox
          class="mt-0 pa-0"
          hide-details
          :input-value="item.visible"
          @change="changeVisible(item, $event)"
        ></v-checkbox>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="460px">
      <v-card class="pa-4">
        <v-row justify="space-between" class="ma-0 mb-2">
          <div class="text-body-1 font-weight-bold">Выберите товары</div>
        </v-row>
        <v-row align="center" no-gutters>
          <v-btn depressed color="primary" @click="approve">Подтвердить</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="selectAll">Выбрать все</v-btn>
        </v-row>
        <search
          class="my-3"
          max-width="300px"
          :rounded="false"
          always
          v-model="search"
        ></search>
        <v-col class="px-0" style="max-height: 500px; overflow-y: auto">
          <v-col
            v-for="(el, index) in nomenclatureList"
            class="pa-0"
            :key="el.id"
          >
            <v-divider style="width: 100%" v-if="index" class="my-2" />
            <v-row class="ma-0" align="center">
              <div style="max-width: 90%">{{ el.name }}</div>
              <v-spacer></v-spacer>
              <v-checkbox
                multiple
                :value="el.id"
                hide-details
                class="ma-0"
                v-model="selected"
              ></v-checkbox>
            </v-row>
          </v-col>
        </v-col>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { cloneDeep } from "lodash";
import Search from "@/components/template/Search.vue";

export default {
  name: "NomenclatureItemOutlets",
  components: { Search },
  data: () => ({
    headers: [
      { text: "Адрес", value: "outlet" },
      { text: "Видимость", value: "visible" },
    ],
    dialog: false,
    selected: [],
    search: "",
  }),
  methods: {
    changeVisible(item, value) {
      this.$outletNomenclature.list.find(v => v.id === item.id).visible = value
    },
    toggle() {
      if (!this.items.length) return
      const setValue = !this.$outletNomenclature.list[0].visible
      this.$outletNomenclature.list.forEach((v) => (v.visible = setValue));
    },
    async save() {
      const data = [];
      for (const el of this.items) {
        data.push({
          outlet: el.outlet,
          visible: el.visible,
        });
      }
      try {
        this.$nomenclature.loadings.action = true;
        await this.$nomenclature.sendPostSingle(
          "set_visible_by_outlets",
          this.$route.params.nomenclatureId,
          data,
          {}
        );
      } catch {
      } finally {
        this.$nomenclature.loadings.action = false;
      }
    },
    openSetToOtherVisibleOutletNomenclatureModal() {
      if (!this.$nomenclature.list.length)
        this.$nomenclature.loadList({ page_size: "all" });
      this.dialog = true;
    },
    approve() {
      if (!this.selected.length) return
      const data = {
        template_nomenclature: this.$nomenclature.item.id,
        nomenclatures: this.selected
      }
      this.$nomenclature.sendPostSingle('set_visible_to_other', undefined, data, {})
      this.dialog = false
      this.selected = []
      this.search = ''
    },
    selectAll() {
      if (this.selected.length !== this.nomenclatureList.length)
        this.selected = this.nomenclatureList.map((v) => v.id);
      else this.selected = [];
    },
  },
  computed: {
    object() {
      return this.$nomenclature.item;
    },
    outletById() {
      const res = {};
      for (const el of this.$outlet.list) {
        res[el.id] = el;
      }
      return res;
    },
    items() {
      if (!Object.keys(this.outletById).length) return [];
      const items = cloneDeep(this.$outletNomenclature.list);
      items.sort((a, b) => {
        const numberA = this.outletById[a.outlet].number || 0;
        const numberB = this.outletById[b.outlet].number || 0;
        return numberA > numberB ? 1 : -1;
      });
      return items;
    },
    nomenclatureList() {
      if (!this.search) return this.$nomenclature.list;
      return this.$nomenclature.list.filter((v) =>
        v.name.toLowerCase().includes(this.search)
      );
    },
  },
  mounted() {
    this.$eventBus.$on("saveVisibleOutletNomenclature", this.save);
    this.$eventBus.$on(
      "openSetToOtherVisibleOutletNomenclatureModal",
      this.openSetToOtherVisibleOutletNomenclatureModal
    );
    this.$outletNomenclature.loadList({
      nomenclature: this.$route.params.nomenclatureId,
      page_size: "all",
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("saveVisibleOutletNomenclature", this.save);
    this.$eventBus.$off(
      "openSetToOtherVisibleOutletNomenclatureModal",
      this.openSetToOtherVisibleOutletNomenclatureModal
    );
  },
};
</script>

<style scoped></style>
