const m = require("air-vue-model")();
m.url = "product_blank_groups";
m.name = "productBlankGroup";
m.text = {
    itemTitle: "Группа заготовок",
    listTitle: "Группы заготовок",
};
m.default = {
    id: null,
    name: "",
};

m.routes = [
    {
        name: "list",
        component: require("@/admin/views/productBlankGroups/ProductBlankGroupList.vue"),
    },
    {
        name: "item",
        component: require("@/admin/views/productBlankGroups/ProductBlankGroupItem.vue"),
        single: true
    }
];

module.exports = m;
