<template>
  <v-col class="pa-0">
    <v-row
      no-gutters
      align="center"
      class="pa-3"
      v-if="!hideHeader"
      :class="headerClasses"
    >
      <template-list-header
        :model="model"
        :title="title"
        :title-prepend="titlePrepend"
      ></template-list-header>
      <slot name="actions"></slot>
      <template-list-actions
        :name-to="nameTo"
        v-if="showCreateButton"
        :model="model"
        :show-create-button="showCreateButton"
        :create-button-text="createButtonText"
      ></template-list-actions>
    </v-row>
    <v-row no-gutters align="center" class="px-3 pb-2" v-if="$slots.subactions">
      <slot name="subactions"></slot>
    </v-row>
    <slot name="items"></slot>
    <v-col class="pa-0" v-if="!hideItems">
      <v-col class="pa-0" v-for="(obj, index) in list" :key="index">
        <v-divider></v-divider>
        <template-list-element
          :name-to="nameTo"
          :model="model"
          :first="!index"
          :last="index === list.length - 1"
          :object="obj"
          :index="index"
        >
          <template v-slot="{ object, to, index }">
            <slot :object="object" :to="to" :index="index"></slot>
          </template>
        </template-list-element>
      </v-col>
      <v-row
        v-if="list.length === 0 && !queryLoading"
        class="ma-0 pa-2"
        justify="center"
      >
        <div class="text-subtitle-1 secondary--text">Ничего не найдено</div>
      </v-row>
    </v-col>
    <v-pagination
      class="pb-3"
      v-if="
        model.pagination && list.length > 0 && model.pagination.last_page > 1
      "
      :length="model.pagination.last_page"
      :total-visible="7"
      color="primary"
      next-icon="mdi-menu-right"
      prev-icon="mdi-menu-left"
      v-model="model.pagination.page"
    />
  </v-col>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TemplateList",

  props: {
    model: Object,
    hideItems: Boolean,
    nameTo: String,
    fluid: {
      type: Boolean,
      default: false,
    },
    title: String,
    description: String,
    showCreateButton: {
      type: Boolean,
      default: true,
    },
    hideHeader: Boolean,
    autoLoadList: {
      type: Boolean,
      default: true,
    },
    items: Array,
    titlePrepend: String,
    filterList: Function,
    extendFilter: Object,
    headerClasses: String,
    createButtonText: String,
  },

  data: () => ({
    showSearch: false,
    timeout: null,
  }),

  computed: {
    ...mapState({
      queryLoading: (state) => state.helpers.queryLoading,
    }),

    list() {
      const items = this.items || this.model.list || [];
      if (this.filterList) return items.filter((item) => this.filterList(item));
      return items;
    },

    search() {
      return this.model.search;
    },
  },

  methods: {},

  created() {
    if (this.autoLoadList) {
      this.model.loadList(this.extendFilter || undefined);
    }
  },

  watch: {
    showSearch(val) {
      if (val) {
        this.showCreateButton =
          this.$vuetify.breakpoint.mdAndUp ||
          (this.$vuetify.breakpoint.smAndDown && !this.showSearch);
        this.$refs.searchField.focus();
      } else {
        setTimeout(() => {
          this.showCreateButton =
            this.$vuetify.breakpoint.mdAndUp ||
            (this.$vuetify.breakpoint.smAndDown && !this.showSearch);
        }, 200);
        this.model.search = "";
      }
    },

    search() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.loadList, 300);
    },
  },
};
</script>
